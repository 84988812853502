import logo from './logo.svg';
import './App.css';

function App() {
  const syllabus = {
    title: "Semestre 9 SN Parcours Systèmes Embarqués et IoT Critique",
    credits: "30 crédits ECTS",
    sections: [
      {
        title: "SHS SN Semestre 9",
        coefficient: 5,
        courses: [
          { name: "Professional English-LV1-Semestre 9", coefficient: 30 },
          { name: "Anglais Scientifique", coefficient: 50 },
          { name: "Choix 2 Anglais Professionnel - 3A", coefficient: 50 },
          { name: "Anglais Clinique" },
          { name: "Anglais de Cambridge ou Projet" },
          { name: "Bureau d'Etudes Industrielles (BEI/BEE)/Conférence", coefficient: 30 },
          { name: "CHOIX 1 sur 2 SHS SN S9", coefficient: 40 },
          { name: "Careers, Leadership et Management" },
          { name: "IT and Computer Law (SN)", coefficient: 50 },
          { name: "Strategic and Critical Thinking (SN)", coefficient: 50 },
          { name: "Innovation-Entreprenariat - S9" }
        ]
      },
      // ... Add other sections like SYSTEMES TEMPS REELS, RESEAUX EMBARQUES, etc.
    ]
  };
  const email = "atasteper@example.com";
  const subject = "Subject here";
  const body = "Body text here";

  return (
    <div className="App">
       <h1>Abdullah Tasteper</h1> 
      <br/>
    <h4>Access limited, contact me for more info.</h4>
    </div>
  );
}

export default App;
